import { render, staticRenderFns } from "./ModalFormRegion.vue?vue&type=template&id=905f27c6&scoped=true&"
import script from "./ModalFormRegion.vue?vue&type=script&lang=js&"
export * from "./ModalFormRegion.vue?vue&type=script&lang=js&"
import style0 from "./ModalFormRegion.vue?vue&type=style&index=0&id=905f27c6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "905f27c6",
  null
  
)

export default component.exports