<template>
    <b-modal
        id="modalFormRegion"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.save')"
        :title="title"
        footerBgVariant="light"
        headerBgVariant="light"
        scrollable
        size="xl"
        @cancel=""
        @hide="modalClose"
        @ok="save"
    >
        <b-overlay v-if="record != null" :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-form ref="form" @submit.prevent="submit">
                <!-- id, active -->
                <b-form-group
                    :label="record.id === 0 ? '' : $t('form.id_value', {value: record.id})"
                    class="mb-0"
                    label-class="pt-0"
                    label-cols="6"
                    label-size=""
                >
                    <b-form-checkbox
                        id="active"
                        v-model="record.active"
                        class="float-right"
                        name="active"
                        switch
                        unchecked-value="false"
                        value="true"
                    >
                        {{activeText}}
                    </b-form-checkbox>
                </b-form-group>
                <!-- left tabs -->
                <b-card no-body>
                    <b-tabs v-model="tabIndex" card pills vertical>
                        <!-- tab basic -->
                        <b-tab :title="$t('form.tab.basic')" :title-link-class="tabValidBasic">
                            <!-- meta -->
                            <b-form-group
                                :label="$tc('form.meta.data', 0)"
                                class="mb-0 group-title p-0"
                                label-align-sm="left"
                                label-class="font-weight-bold p-0 pl-1"
                                label-cols-sm="12"
                                label-size=""
                            >
                                <b-form-row>
                                    <!-- name -->
                                    <b-form-group
                                        id="group-meta.name"
                                        :label="$t('form.name')"
                                        :state="valid_metaName"
                                        class="col-sm-12 col-md-6"
                                        description=""
                                        label-class="mb-0"
                                        label-for="meta.name"
                                    >
                                        <b-form-input
                                            id="meta.name"
                                            v-model="record.meta.name"
                                            :placeholder="$t('form.required_field')"
                                            :state="valid_metaName"
                                            required
                                        ></b-form-input>
                                    </b-form-group>
                                </b-form-row>
                            </b-form-group>
                            <!-- content -->
                            <b-form-group
                                :label="$t('form.content')"
                                class="mb-0 group-title p-0"
                                label-align-sm="left"
                                label-class="font-weight-bold"
                                label-cols-sm="12"
                                label-size=""
                            >
                                <b-form-row>
                                    <!-- state_id -->
                                    <b-form-group
                                        id="group-continent_id"
                                        :label="$tc('register.state', 1)"
                                        :state="valid_state"
                                        class="col-sm-12 col-md-6"
                                        description=""
                                        label-class="mb-0"
                                        label-for="state_id"
                                    >
                                        <b-form-select
                                            id="state_id"
                                            v-model="record.state_id"
                                            :options="getStates"
                                            :state="valid_state"
                                            required
                                        >
                                        </b-form-select>
                                    </b-form-group>
                                    <!-- order -->
                                    <b-form-group
                                        id="group-order"
                                        :label="$t('form.order')"
                                        class="col-sm-6 col-md-3"
                                        description=""
                                        label-class="mb-0"
                                        label-for="order"
                                    >
                                        <b-form-input
                                            id="order"
                                            v-model="record.order"
                                            type="number"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-form-row>
                            </b-form-group>
                        </b-tab>
                        <!-- tab pictures -->
                        <b-tab :title="$t('form.tab.picture')">
                            <!--                            <PictureManager-->  <!--TODO add picture manager-->
                            <!--                                :picture-manager-bus="pictureManagerBus"-->
                            <!--                                @eventUpdate="eventPictureManagerUpdate"-->
                            <!--                            >-->
                            <!--                            </PictureManager>-->
                        </b-tab>
                        <!-- tab notes -->
                        <b-tab :title="$t('form.tab.notes')">
                            <!-- locale-->
                            <b-form-group
                                :label="$t('form.locale.locale')"
                                class="mb-0 group-title p-0"
                                label-align-sm="left"
                                label-class="font-weight-bold p-0 pl-1"
                                label-cols-sm="12"
                                label-size=""
                            >
                                <b-card no-body>
                                    <b-tabs card pills>
                                        <b-tab v-for="locale in data.locale"
                                               :active="locale.locale === $i18n.locale"
                                               :title="locale.locale" style="background: white">
                                            <FormRegionLocale
                                                :locale="locale.locale"
                                                :record.sync="setRecordLocale[locale.locale]"
                                            ></FormRegionLocale>
                                        </b-tab>
                                    </b-tabs>
                                </b-card>
                            </b-form-group>
                        </b-tab>
                    </b-tabs>
                </b-card>
                <!--  -->
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
import Vue from "vue";
import utilsGeneral from "@/utils/utilsGeneral";
import FormRegionLocale from "./FormRegionLocale";
// @group Form.REGISTER
export default {
    name: "ModalFormRegion",
    components: {FormRegionLocale},
    props: {
        uri: null,
        modalBus: Object,
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            recordId: 0,
            imagesFolder: 'region',
            titleDefault: this.$tc('register.region', 1),
            valid: null,
            tabIndex: 0,
            recordDefault: {
                id: 0,
                active: true,
                order: 0,
                state_id: null,
                meta: {
                    name: '',
                },
                locale: {},
                pictures_order: [],
                pictures: [],
            },
            record: null,
            data: {},
            pictureManagerBus: new Vue(),
        }
    },
    mounted() {
        this.modalBus.$on('modalOpen', (recordId) => {
            this.recordId = recordId;
            this.modalOpen();
        });
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <-- modal -->
        modalOpen() {
            if (this.recordId > 0) {
                this.loadEdit();
                return;
            }
            this.loadNew();
        },
        modalClose() {
            this.showOverlay(false);
        },
        modalHide() {
            this.$bvModal.hide('modalFormRegion');
        },

        // <--  -->
        loadNew() {
            this.showOverlay(true);
            // <-- axios -->
            this.axios.get(this.uri.uri1 + `/getForeignData`, this.__config)
                .then((response) => {
                    this.valid = null;
                    this.data = response.data;
                    this.record = utilsGeneral.clone(this.recordDefault);
                    // <--  -->
                    this.$bvModal.show('modalFormRegion');
                    let record = {
                        pictures_order: this.record.pictures_order,
                        pictures: this.record.pictures
                    };
                    this.$nextTick(function () {
                        this.pictureManagerBus.$emit('recordLoad', record);
                    });
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // on error executed
                })
                .finally(function () {
                    // always executed
                });
        },
        loadEdit() {
            this.showOverlay(true);
            // <-- axios -->
            this.axios.get(this.uri.main + `/${this.recordId}`, this.__config)
                .then((response) => {
                    this.valid = null;
                    this.data = response.data.data;
                    this.record = response.data.record;
                    // <--  -->
                    this.$bvModal.show('modalFormRegion');
                    let record = {
                        pictures_order: this.record.pictures_order,
                        pictures: this.record.pictures
                    };
                    this.$nextTick(function () {
                        this.pictureManagerBus.$emit('recordLoad', record)
                    });
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
        // <-- events pictureManager -->
        eventPictureManagerUpdate(data) {
            this.$set(this.record, 'pictures_order', data.pictures_order);
            this.$set(this.record, 'pictures', data.pictures);
        },
        // <-- form -->
        submit() {
            this.valid = false;
            if (this.$refs.form === undefined) {
                return;
            }
            this.valid = this.$refs.form.checkValidity();
        },
        // <-- record -->
        save(bvModalEvent) {
            // <-- prevent default to close modal -->
            bvModalEvent.preventDefault();
            // <-- check form validity from modal -->
            this.submit();
            if (!this.valid) {
                return;
            }
            // <-- if form valid save record -->
            if (this.record.id > 0) {
                this.baseUpdate();
                return;
            }
            this.baseInsert();
        },
        baseInsert() {
            // <-- form data -->
            let formData = new FormData();
            formData.append('record', JSON.stringify(this.record));
            // <-- api -->
            const config = {
                headers: this.__getFormDataHeaders(false)
            }
            // <-- axios -->
            this.axios.post(this.uri.main, formData, config)
                .then((response) => {
                    this.data = response.data;
                    // this.setTableData();
                    this.hideModal();
                })
                .catch((error) => {
                    console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
        baseUpdate() {
            this.showOverlay(true);
            // <-- set formData -->
            let formData = new FormData();
            let imagesUpdate = [];
            formData.append('record', JSON.stringify(this.record));
            // <-- form data -->
            if (this.record.pictures !== undefined) {
                this.record.pictures.forEach((picture, index) => {
                    if (picture.id === 0) {
                        formData.append('imagesFolder', this.imagesFolder);
                        formData.append('files[' + index + ']', picture.file);
                        formData.append('keys[' + index + ']', picture.key);
                    } else {
                        imagesUpdate.push(picture);
                    }
                });
            }
            formData.append('imagesUpdate', JSON.stringify(imagesUpdate));
            const config = {
                headers: this.__getFormDataHeaders(false)
            }
            // <-- axios -->
            this.axios.post(this.uri.uri1 + '/updateAll' + `/${this.record.id}`, formData, config)
                .then((response) => {
                    this.data = response.data;
                    this.$emit('update', this.data);
                    this.modalHide();
                    this.showOverlay(false);
                }).catch((error) => {
                // if (error.response) {}
            });
        },
    },
    computed: {
        activeText: {
            get() {
                return !this.record.active || this.record.active === 'false' || this.record.active === undefined
                    ? this.$t('form.inactive')
                    : this.$t('form.active');
            }
        },
        title: {
            get() {
                if (this.recordId > 0) {
                    return this.$t('form.edit') + ': ' + this.titleDefault.toUpperCase();
                }
                return this.$t('form.add') + ': ' + this.titleDefault.toUpperCase();
            }
        },
        getStates() {
            let states = [
                {value: null, text: '/', disabled: false},
            ];
            if (this.data.states == null) {
                return states;
            }
            // <--  -->
            let meta = false;
            let locale = {};
            this.data.states.forEach((state, index) => {
                meta = false;
                locale = state.locale[this.$i18n.locale];
                // <-- if missing locale data -->
                if (locale === undefined) {
                    meta = true;
                    locale = state.meta;
                }
                states.push(
                    {
                        value: state.id,
                        text: locale.name + (meta ? this.$t('form.meta.message', {msg: this.$t('form.name').toLowerCase()}) : ''),
                        disabled: false
                    }
                );
            });
            // <--  -->
            return states;
        },
        // <-- locale -->
        setRecordLocale() {
            this.data.locale.forEach((value, index) => {
                // <-- add missing locale record -->
                if (Object.keys(this.record.locale).indexOf(value.locale) === -1) {
                    this.$set(this.record.locale, value.locale, {
                        name: '',
                        notes: ''
                    });
                }
            });
            return this.record.locale;
        },
        // <-- meta -->
        valid_metaName() {
            if (this.valid === null) {
                return null;
            }
            return this.record.meta.name.length > 0 ? null : false;
        },
        // <-- valid foreign data -->
        valid_state() {
            if (this.valid === null) {
                return null;
            }
            return this.record.state_id > 0 ? null : false;
        },
        // <-- tabs valid -->
        tabValidBasic() {
            if (this.valid === null) {
                return null;
            }
            let tab = 0;
            if (tab === this.tabIndex) {
                return '';
            }
            let valid = this.valid_metaName == null && this.valid_state == null;
            return valid ? null : ['text-danger'];
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
.tab-valid {
    outline: blue;
}
</style>
